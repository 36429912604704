.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.App-header img {
  width: 250px;
  object-fit: contain;
  height: 250px;
  
}

p {
  color: #fff;
  font-size: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}